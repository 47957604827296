export const HOST_URL = "https://localhost:8000";

export const INVALID_CRED = "Invalid credential !";

export const SECRET_KEY = "shukrana_mushkurana";

export const API_KEY = "AIzaSyBgkTZ8lb9ZKj_DI7z7T9euwULgr5fDf1Q";

export const GOOGLE_MAP_API_URL =
  "https://maps.googleapis.com/maps/api/geocode/json";

export const GOOGLE_PLACE_API_URL =
  "https://maps.googleapis.com/maps/api/place/textsearch/json";

export const GOOGLE_CLIENT_ID =
  "1008544511557-9jlk8iviuhnvpatq9bj5d5geeg9e0jr5.apps.googleusercontent.com";
// export const GOOGLE_CLIENT_ID =
//   "521543791680-26jrl70j0duivecoh80f4jpun63cjbpn.apps.googleusercontent.com"; OLD

// export const FACEBOOK_APP_ID = "2809614726027672";
export const FACEBOOK_APP_ID = "293475139351508";

export const EDAMAM_APP_ID = "2bbcafaf";

export const EDAMAM_APP_KEY = "f779cbf3ef41e8fc15a42df1cff35d0d";

export const RESTAURANT_ADMIN_URL = `${window.location.host === "localhost:3000"?"https://pickypigsrestaurantsadmin.herokuapp.com":"https://pickypigsrestaurantsadmin.herokuapp.com"}`;

// export const RESTAURANT_ADMIN_URL = `${
// //   window.location.host === "192.168.1.187:3000"
// //     ? "http://192.168.1.187:3001"
// //     : "http://103.232.124.169:11029"
//     window.location.host === "localhost:3000"
//     ? "http://localhost:3001"
//     : "http://103.232.124.169:11029"
// }`;

export const USER_URL = "https://pickypigs.charlieandco.co.nz/";

export const SERVER_URL = "https://api.pickypigs.com:5003";


export const DEFAULT_LOCATION = {
  description: "Tauranga, New Zealand",
  matched_substrings: [
      {
          length: 8,
          offset: 0,
      },
      {
          length: 11,
          offset: 10,
      },
  ],
  place_id: "ChIJ5WTUe-d2bm0RJpmjQ2HvAAU",
  reference: "ChIJ5WTUe-d2bm0RJpmjQ2HvAAU",
  structured_formatting: {
      main_text: "Tauranga",
      main_text_matched_substrings: [
          {
              "length": 8,
              "offset": 0
          }
      ],
      secondary_text: "New Zealand",
      secondary_text_matched_substrings: [
          {
              "length": 11,
              "offset": 0
          }
      ]
  },
  terms: [
      {
          offset: 0,
          value: "Tauranga"
      },
      {
          offset: 10,
          value: "New Zealand"
      }
  ],
  "types": [
      "locality",
      "political",
      "geocode"
  ]
};


/**Geo location of the Tauranga, New Zealand */
export const DEFAULT_LAT = -37.6869653;
export const DEFAULT_LNG = 176.1654272;
